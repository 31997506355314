import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/markdown-page',
    component: ComponentCreator('/markdown-page', '335'),
    exact: true
  },
  {
    path: '/docs',
    component: ComponentCreator('/docs', '09f'),
    routes: [
      {
        path: '/docs/intro',
        component: ComponentCreator('/docs/intro', 'aed'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/pelayanan',
        component: ComponentCreator('/docs/pelayanan', '582'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/pengendalian',
        component: ComponentCreator('/docs/pengendalian', '219'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/penunjang',
        component: ComponentCreator('/docs/penunjang', 'c14'),
        exact: true,
        sidebar: "tutorialSidebar"
      },
      {
        path: '/docs/umum',
        component: ComponentCreator('/docs/umum', '20c'),
        exact: true,
        sidebar: "tutorialSidebar"
      }
    ]
  },
  {
    path: '/',
    component: ComponentCreator('/', '541'),
    exact: true
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
